import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc } from "firebase/firestore";
import _ from 'lodash';

function clean(data) {
  return _.chain(data)
    .omit('id')
    .omitBy(_.isUndefined)
    .value();
}

class FirebaseDao {
  constructor(collectionName) {
    this.firestore = getFirestore();
    this.collectionName = collectionName;
  }

  async select(...queryConstraints) {
    const collRef = collection(this.firestore, this.collectionName);
    const snapshot = await getDocs(query(collRef, ...queryConstraints));
    return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  }

  async get(id, required = true) {
    const docRef = doc(this.firestore, this.collectionName, id);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      if (required) {
        throw new Error(`Document ${id} does not exist in ${this.collectionName}.`);
      } else {
        return null;
      }
    } else {
      return {...docSnap.data(), id}
    }
  }

  async add(data) {
    const collRef = collection(this.firestore, this.collectionName);
    await addDoc(collRef, clean(data));
  }

  async set(id, data) {
    const docRef = doc(this.firestore, this.collectionName, id);
    await setDoc(docRef, clean(data));
  }

  async update(id, data) {
    const docRef = doc(this.firestore, this.collectionName, id);
    await updateDoc(docRef, data, { merge: true });
  }

  async delete(id) {
    const docRef = doc(this.firestore, this.collectionName, id);
    await deleteDoc(docRef);
  }
}

export default FirebaseDao;
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FirebaseDao from "../firebase/dao";
import { useAuth } from "../auth/auth-provider";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import * as Sentry from "@sentry/react";

const recipeDao = new FirebaseDao("recipes");

function toForm(recipe) {
  return {
    ...recipe,
    ingredients: recipe.ingredients?.join("\n") ?? "",
    instructions: recipe.instructions?.join("\n") ?? "",
    tags: recipe.tags?.join(", ") ?? "",
  };
}

function fromForm(form) {
  return {
    ...form,
    ingredients: form.ingredients?.split("\n").map(s => s.trim()) ?? [],
    instructions: form.instructions?.split("\n").map(s => s.trim()) ?? [],
    tags: form.tags?.split(",").map(s => s.trim()) ?? [],
  };
}

function AddEditRecipePage() {
  const { user } = useAuth();
  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchRecipe = async (id) => {
    try {
      const recipe = await recipeDao.get(id);
      setFormData(toForm(recipe));
    }
    catch (error) {
      toast.error(`There was an error fetching recipe: ${error.message} (${error.code})`);
      Sentry.captureException(error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchRecipe(id);
    } else {
      setFormData({uid: user.uid, images: []});
    }
  }, [id, user.uid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleFileChange = (e) => {
    const selectedImages = Array.from(e.target.files).map(file => {
      return {
        id: crypto.randomUUID(),
        file: file,
        url: URL.createObjectURL(file)
      };
    });
    setFormData({ ...formData, images: [...formData.images, ...selectedImages] });
  }

  const uploadImage = async (image) => {
    const storage = getStorage();
    const storageRef = ref(storage, `/images/${image.id}`);
    const uploadResult = await uploadBytes(storageRef, image.file);
    const url = await getDownloadURL(uploadResult.ref);
    return { id: image.id, url };
  };

  const uploadImages = (images) => {
    return Promise.all(images.map(image =>
      image.file ? uploadImage(image) : image
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let images = formData.images;
      if (images.some(image => image.file)) {
        let toastId = toast.loading("Uploading images...");
        try {
          images = await uploadImages(images);
        }
        finally {
          toast.dismiss(toastId);
        }
      }
      const recipe = { ...fromForm(formData), images };
      if (id) {
        await recipeDao.set(id, recipe);
      } else {
        await recipeDao.add(recipe);
      }
      navigate("/recipes");
    }
    catch (error) {
      toast.error(`There was an error saving recipe: ${error.message} (${error.code})`);
      Sentry.captureException(error);
    }
  }

  if (!formData) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">{id ? 'Edit' : 'Add'} Recipe</h1>
      <form className="box" onSubmit={handleSubmit} noValidate>
        <fieldset>
          
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input className="input" type="text" name="name" value={formData.name || ''} onChange={handleChange} autoFocus />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Description</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <textarea className="textarea" name="description" value={formData.description || ''} onChange={handleChange} rows="2" />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Ingredients</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <textarea className="textarea" name="ingredients" value={formData.ingredients || ''} onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Instructions</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <textarea className="textarea" name="instructions" value={formData.instructions || ''} onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Source</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input className="input" type="text" name="source" value={formData.source || ''} onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Tags</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input className="input" type="text" name="tags" value={formData.tags || ''} onChange={handleChange} />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Images</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="is-flex">
                    {formData.images && formData.images.map(image =>
                      <div className="m-1" key={image.id}>
                        <figure className="image is-128x128 is-clipped">
                          <img key={image.id} style={{ width: '128px', height: '128px', objectFit: 'cover' }} src={image.url} alt="" />
                        </figure>
                      </div>
                    )}
                  </div>
                  <div className="file is-normal">
                    <label className="file-label">
                      <input className="file-input" type="file" accept="image/*" multiple onChange={handleFileChange}/>
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">
                          Add Image(s)
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button className="button is-info">Save</button>
            </div>
            <div className="control">
              <NavLink to="/recipes" className="button is-info is-light">Cancel</NavLink>
            </div>
          </div>
        </fieldset>
      </form>

    </section>
  );
}

export default AddEditRecipePage;
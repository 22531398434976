import { useState } from 'react';
import { useAuth } from './auth-provider';
import { NavLink } from 'react-router-dom';
import { translate } from '../firebase/errors';
import * as Sentry from "@sentry/react";

function ForgotPasswordPage() {
  const { forgotPassword } = useAuth();
  const [formData, setFormData] = useState({email: ''});
  const [error, setError] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword(formData);
    }
    catch (e) {
      const { code } = e;
      const message = translate(code);
      setError(message || `An error has occurred: ${code}`);
      if (!message) {
        Sentry.captureException(e);
      }
    }
  }
  return (
    <section className="section">
      <div className="container has-text-centered">
        <div className="column is-4 is-offset-4">
          <h3 className="title">Forgot Password</h3>
          <div className="block has-text-grey-lighter">
            <i className="fa-solid fa-circle-user fa-6x"></i>
          </div>
          {error && <div className="notification is-danger is-light">{error}</div>}
          <div className="box">
            <form onSubmit={handleSubmit} noValidate>
              <div className="field">
                <div className="control has-icons-left">
                  <input className="input" type="email" name="email" value={formData.email} placeholder="Email" autoFocus onChange={handleChange}/>
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-envelope"></i>
                  </span>
                </div>
              </div>
              <button className="button is-info is-fullwidth">Send Password Reset Email</button>
            </form>
          </div>
          <p>
            <NavLink to="/signup">Sign Up</NavLink>
            &nbsp;·&nbsp;
            <NavLink to="/login">Login</NavLink>
          </p>
        </div>
      </div>
    </section>
  );
}

export default ForgotPasswordPage;
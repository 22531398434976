function translate(code) {
  switch (code) {
    case "auth/missing-email": return "Email is required";
    case "auth/invalid-email": return "Email is not valid";
    case "auth/email-already-in-use": return "Email is already in use";
    case "auth/missing-password": return "Password is required";
    case "auth/weak-password": return "Password is not strong enough";
    case "auth/user-not-found": return "Login failed";
    case "auth/wrong-password": return "Login failed";
    default: return null;
  }
}

export { translate };

import { NavBar } from './nav-bar';

function MainLayout({ children }) {
  return (
    <>
      <NavBar/>
      {children}
    </>
  );
}

export { MainLayout };

import { useEffect, useState } from 'react';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/auth-provider';

function NavBar() {
  const { user, logout } = useAuth();
  const location = useLocation();

  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => setIsActive(!isActive);

  useEffect(() => {
    if (isActive) {
      setIsActive(false);
    }
    const el = document.activeElement;
    if (el?.classList?.contains("navbar-item")) {
      el.blur();
    }
  }, [location]);

  return (
    <nav className="navbar is-light" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <NavLink className="navbar-item" to="/">
          <img src="/logo28.png" alt="logo" width="28" height="28"/>
          <span className="has-text-weight-bold is-size-4 ml-2">My Recipes</span>
        </NavLink>
        <a role="button" className={cx('navbar-burger', { 'is-active': isActive })} aria-label="menu" aria-expanded="false" data-target="navbar" onClick={toggleActive}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbar" className={cx('navbar-menu', { 'is-active': isActive })}>
        <div className="navbar-start">
          <NavLink className="navbar-item" to="/recipes">Recipes</NavLink>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              {user ?
                <button className="button is-info" onClick={logout}>Log out</button> :
                <>
                  <NavLink className="button is-info" to="/signup"><strong>Sign up</strong></NavLink>
                  <NavLink className="button is-info is-light" to="/login">Log in</NavLink>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export { NavBar };

import RecipesPage from "./recipes";
import RecipePage from "./view";
import AddEditRecipePage from "./add-edit";
import { Authenticated } from "../auth/authenticated";

const routes = [
  {
    path: "/recipes",
    children: [
      { path: "", element: <Authenticated><RecipesPage/></Authenticated>},
      { path: "new", element: <Authenticated><AddEditRecipePage/></Authenticated>},
      { path: ":id", element: <Authenticated><RecipePage/></Authenticated>},
      { path: ":id/edit", element: <Authenticated><AddEditRecipePage/></Authenticated>}
    ]
  }
];

export default routes;
import { NavLink } from "react-router-dom";
import { useEffect, useReducer, useState } from "react";
import { where } from "firebase/firestore";
import { useAuth } from "../auth/auth-provider";
import { toast } from "react-toastify";
import FirebaseDao from "../firebase/dao";
import cx from 'classnames';
import _ from "lodash";
import * as Sentry from "@sentry/react";

const recipeDao = new FirebaseDao("recipes");

function reducer(state, action) {
  let { all, query } = state;
  switch (action.type) {
    case "set-recipes":
      all = action.recipes;
      break;
    case "set-query":
      query = action.query.trim().toLowerCase();
      break;
    default:
      throw new Error();
  }

  if (!all) {
    return { all, query, filtered: null };
  }
  else if (query.length === 0) {
    return { all, query, filtered: all };
  }
  else {
    return { all, query, filtered: filterRecipes(all, query) };
  }
}

function filterRecipes(recipes, query) {
  return recipes.filter(r => recipeMatches(r, query));
}

function recipeMatches(recipe, query) {
  return recipe.name?.toLowerCase().includes(query) ||
    recipe.description?.toLowerCase().includes(query) ||
    recipe.tags?.some(t => t.toLowerCase().includes(query)) ||
    recipe.ingredients?.some(i => i.toLowerCase().includes(query)) ||
    recipe.instructions?.some(i => i.toLowerCase().includes(query));
}

function RecipesPage() {
  const [recipes, dispatch] = useReducer(reducer, { all: null, filtered: null, query: "" });
  const { user } = useAuth();
  const [confirmDelete, setConfirmDelete] = useState({show: false, id: null});
  const [view, setView] = useState("tile");

  const setRecipes = (recipes) => dispatch({ type: "set-recipes", recipes });
  const setQuery = (query) => dispatch({ type: "set-query", query });

  const fetchRecipes = async (uid) => {
    try {
      const recipes = await recipeDao.select(where("uid", "==", uid));
      setRecipes(recipes);
    }
    catch (error) {
      toast.error(`There was an error fetching recipes: ${error.message} (${error.code})`);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchRecipes(user.uid);
  }, [user.uid]);

  const showConfirmDialog = (id) => setConfirmDelete({show: true, id: id});
  const hideConfirmDialog = () => setConfirmDelete({show: false, id: null});
  const deleteRecipe = async (id) => {
    try {
      await recipeDao.delete(id);
      fetchRecipes(user.uid);
    }
    catch (error) {
      toast.error(`There was an error deleting recipe: ${error.message} (${error.code})`);
      Sentry.captureException(error);
    }
    finally {
      hideConfirmDialog();
    }
  }

  const toggleFavorite = async (id) => {
    try {
      const recipe = recipes.all.find(r => r.id === id);
      const favorite = recipe.favorite;
      await recipeDao.update(id, { favorite: !favorite });
      const updatedRecipes = recipes.all.map(r => (r.id === id) ? { ...r, favorite: !favorite } : r);
      setRecipes(updatedRecipes);
    }
    catch (error) {
      toast.error(`There was an error updating favorite: ${error.message} (${error.code})`);
      Sentry.captureException(error);
    }
  }

  if (!recipes.all) {
    return null;
  }

  return (
    <>
      {confirmDelete.show && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Delete Recipe</p>
            </header>
            <section className="modal-card-body">
              <p>Are you sure you want to delete this recipe?</p>
            </section>
            <footer className="modal-card-foot">
              <button className="button" onClick={() => hideConfirmDialog()}>Cancel</button>
              <button className="button is-danger" onClick={() => deleteRecipe(confirmDelete.id)}>Delete</button>
            </footer>
          </div>
        </div>
      )}

      <section className="section">
        <h1 className="title">Recipes</h1>

        <div className="columns is-mobile">
          <div className="column">
            <div className="control is-expanded has-icons-left has-icons-right">
              <input className="input" type="text" placeholder="Search..." value={recipes.query} onChange={e => setQuery(e.target.value)} />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-magnifying-glass"></i>
              </span>
              {recipes.query.length > 0 && (
                <span className="icon is-small is-right is-clickable" onClick={() => setQuery("")}>
                  <i className="fa-solid fa-circle-xmark"></i>
                </span>
              )}
            </div>
          </div>
          <div className="column is-narrow">
            <div className="buttons has-addons is-right">
              <button className={cx('button', { 'is-info is-selected': view === 'tile' })} onClick={() => setView('tile')}>
                <span className="icon">
                  <i className="fa-solid fa-table-cells-large"></i>
                </span>
              </button>
              <button className={cx('button', { 'is-info is-selected': view === 'list' })} onClick={() => setView('list')}>
                <span className="icon">
                  <i className="fa-solid fa-bars"></i>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="columns is-mobile">
          <div className="column">
            <NavLink to="/recipes/new" className="button is-info is-pulled-right">Add Recipe</NavLink>
          </div>
        </div>

        {view === 'tile' && (
          <div className="columns is-multiline is-8">
            {recipes.filtered.map((recipe) => (
              <div key={recipe.id} className="column is-one-third">
                <div className="box is-flex is-flex-direction-column" style={{ height: '100%' }}>
                  <article className="media mb-1">
                    <div className="media-left">
                      <figure className="image is-64x64 is-clipped">
                        <img key={recipe.id} style={{ width: '64px', height: '64px', objectFit: 'cover' }} src={recipe.images[0]?.url ?? "/recipe.png"} alt="" />
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title is-4">{recipe.name}</p>
                      <p className="subtitle is-6">{_.truncate(recipe.description, {length: 50})}</p>
                    </div>
                    <div className="media-right">
                      <a className="has-text-danger-dark" onClick={() => toggleFavorite(recipe.id)}>
                        <span className="icon is-small">
                          <i className={cx('fa-heart', { 'fa-solid': recipe.favorite, 'fa-regular': !recipe.favorite })}></i>
                        </span>
                      </a>
                    </div>
                  </article>
                  {recipe.tags.length > 0 && (
                    <div className="tags mt-1 mb-0">
                      {recipe.tags.map((tag) => (
                        <span key={tag} className="tag">{tag}</span>
                      ))}
                    </div>
                  )}
                  <div className="buttons is-right are-small mt-auto pt-1">
                    <NavLink className="button is-info is-light" to={`/recipes/${recipe.id}`}>View</NavLink>
                    <NavLink className="button is-info is-light" to={`/recipes/${recipe.id}/edit`}>Edit</NavLink>
                    <button className="button is-danger is-light" onClick={() => showConfirmDialog(recipe.id)}>Delete</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {view === 'list' && (
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th width="1%"></th>
              </tr>
            </thead>
            <tbody>
              {recipes.filtered.map((recipe) => (
                <tr key={recipe.id}>
                  <td>{recipe.name}</td>
                  <td>{_.truncate(recipe.description, {length: 50})}</td>
                  <td>
                    <div className="field is-grouped">
                      <p className="control">
                        <NavLink className="button is-info is-light is-small" to={`/recipes/${recipe.id}`}>View</NavLink>
                      </p>
                      <p className="control">
                        <NavLink className="button is-info is-light is-small" to={`/recipes/${recipe.id}/edit`}>Edit</NavLink>
                      </p>
                      <p className="control">
                        <button className="button is-danger is-light is-small" onClick={() => showConfirmDialog(recipe.id)}>Delete</button>
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </section>
    </>
  );
}

export default RecipesPage;
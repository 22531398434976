import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FirebaseDao from "../firebase/dao";
import cx from 'classnames';
import * as Sentry from "@sentry/react";

const recipeDao = new FirebaseDao("recipes");

function RecipePage() {
  const [recipe, setRecipe] = useState(null);
  const { id } = useParams();

  const fetchRecipe = async (id) => {
    try {
      const recipe = await recipeDao.get(id);
      setRecipe(recipe);
    }
    catch (error) {
      toast.error(`There was an error fetching recipe: ${error.message} (${error.code})`);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchRecipe(id);
  }, [id]);

  const toggleFavorite = async (id) => {
    try {
      const favorite = recipe.favorite;
      await recipeDao.update(id, { favorite: !favorite });
      setRecipe({ ...recipe, favorite: !favorite });
    }
    catch (error) {
      toast.error(`There was an error updating favorite: ${error.message} (${error.code})`);
      Sentry.captureException(error);
    }
  }

  if (!recipe) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">Recipe</h1>
      <div className="container box">
        <div className="columns is-mobile">
          <div className="column">
            <h2 className="subtitle">{recipe.name}</h2>
          </div>
          <div className="column is-narrow">
            <a className="has-text-danger-dark" onClick={() => toggleFavorite(recipe.id)}>
              <span className="icon is-small">
                <i className={cx('fa-heart', { 'fa-solid': recipe.favorite, 'fa-regular': !recipe.favorite })}></i>
              </span>
            </a>
          </div>  
        </div>
        <div className="block">
          <strong>Description</strong>
          <p>{recipe.description}</p>
        </div>
        <div className="block">
          <strong>Ingredients</strong>
          <div className="content">
            <ul>
              {recipe.ingredients.map((ingredient, index) =>
                <li key={index}>{ingredient}</li>
              )}
            </ul>
          </div>
        </div>
        <div className="block">
          <strong>Instructions</strong>
          <div className="content">
            <ul>
              {recipe.instructions.map((instruction, index) =>
                <li key={index}>{instruction}</li>
              )}
            </ul>
          </div>
        </div>
        {recipe.source &&         
          <div className="block">
            <strong>Source</strong>
            <p>
              <a href={recipe.source} target="_blank" rel="noreferrer">
                {recipe.source} <i className="fa-solid fa-external-link-alt fa-xs"></i>
              </a>
            </p>
          </div>
        }
        {recipe.tags &&
          <div className="block">
            <strong>Tags</strong>
            <div className="tags">
              {recipe.tags.map((tag, index) =>
                <span className="tag" key={index}>{tag}</span>
              )}
            </div>
          </div>
        }
        {recipe.images && recipe.images.length > 0 &&
          <div className="block">
            <strong>Images</strong>
            <div className="is-flex">
              {recipe.images.map(image =>
                <div className="m-1" key={image.id}>
                  <figure className="image is-128x128 is-clipped">
                    <img key={image.id} style={{ width: '128px', height: '128px', objectFit: 'cover' }} src={image.url} alt="" />
                  </figure>
                </div>
              )}
            </div>
          </div>
        }
      </div>
    </section>
  );
}

export default RecipePage;
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
getAnalytics();

const emulator = process.env.REACT_APP_FIREBASE_EMULATOR;
if (emulator) {
  console.log(`Using Firebase Emulator: ${emulator}`);
  connectAuthEmulator(getAuth(), `http://${emulator}:9099`);
  connectFirestoreEmulator(getFirestore(), emulator, 8080);
  connectStorageEmulator(getStorage(), emulator, 9199);  
}
else {
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_FIREBASE_RECAPTCHA_KEY),
    isTokenAutoRefreshEnabled: true
  });  
}

export default app;
import './sentry';
import './firebase';
import 'bulma/css/bulma.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { MainLayout } from './layout/main-layout';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './auth/auth-provider';
import authRoutes from './auth/routes';
import recipeRoutes from './recipes/routes';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import * as Sentry from "@sentry/react";

const homeRoutes = [
  { path: "/", element: <HomePage/>},
];

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <AuthProvider><MainLayout><Outlet/></MainLayout><ToastContainer/></AuthProvider>,
    children: [
      ...homeRoutes,
      ...authRoutes,
      ...recipeRoutes,
      { path: "*", element: <NotFoundPage/>}
    ]
  }
]);

function App() {
  const [authReady, setAuthReady] = useState(false);

  useEffect(() => {
    if (!authReady) {
      getAuth().authStateReady().then(() => setAuthReady(true));
    }
  });

  if (!authReady) {
    return null;
  }

  return <RouterProvider router={router} />;
}

function HomePage() {
  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <div className="columns is-vcentered">
              <div className="column is-half has-text-centered">
                <img src="/logo192.png" alt="logo" width="192" height="192" />
              </div>
              <div className="column is-half has-text-centered">
                <h1 className="title">My Recipes</h1>
                <p>A simple recipe manager.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function NotFoundPage() {
  return (
    <section className="section">
      <h1 className="title">Not Found</h1>
      <h2 className="subtitle">
        The requested page was not found.
      </h2>
    </section>
  );
}

export default App;

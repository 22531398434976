import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import * as Sentry from "@sentry/react";

const AuthContext = React.createContext(null);
const auth = getAuth();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = React.useState(auth.currentUser);

  useEffect(() => {
    return auth.onAuthStateChanged((newUser) => {
      if (newUser !== user) {
        setUser(newUser);
      }
      
    });
  }, [user]);

  useEffect(() => {
    Sentry.setUser({ id: user?.uid, email: user?.email });
  }, [user]);

  const signup = async ({ email, password, confirmPassword }) => {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    sendEmailVerification(user);
    const origin = location.state?.from?.pathname || '/recipes';
    navigate(origin);
  };

  const login = async ({ email, password }) => {
    await signInWithEmailAndPassword(auth, email, password);
    const origin = location.state?.from?.pathname || '/recipes';
    navigate(origin);
  };

  const logout = async () => {
    await signOut(auth);
    navigate('/');
  };

  const forgotPassword = async ({ email }) => {
    await sendPasswordResetEmail(auth, email);
    navigate('/login');
  };

  const value = {
    user,
    signup,
    login,
    logout,
    forgotPassword
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuth };